export const STRIPE_PAYMENTS = {
  CARD: 'card',
  GOOGLE_PAY: 'google_pay',
  APPLE_PAY: 'apple_pay',
  KLARNA: 'klarna',
  AFFIRM: 'affirm',
};

export const CARD_PAYMENT_METHODS = [
  STRIPE_PAYMENTS.CARD,
  STRIPE_PAYMENTS.GOOGLE_PAY,
  STRIPE_PAYMENTS.APPLE_PAY,
];

export const SETUP_FUTURE_USAGE = {
  OFF_SESSION: 'off_session',
  ON_SESSION: 'on_session',
};

export const PAY_LATER_MINIMUM_AMOUNT = 6000;
