import pick from 'lodash/pick';
import uniqBy from 'lodash/uniqBy';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { queryOwnListingByAuthorId } from '../../util/api';
import { denormalisedResponseEntities, getImageVariantInfo } from '../../util/data';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
} from '../../util/urlHelpers';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { JH_LISTING_TYPES } from '../../util/types';

const ITEM_PER_PAGE = 96;

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/StoreListingPage/SET_INITIAL_VALUES';

export const SHOW_LISTING_REQUEST = 'app/StoreListingPage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_SUCCESS = 'app/StoreListingPage/SHOW_LISTING_SUCCESS';
export const SHOW_LISTING_ERROR = 'app/StoreListingPage/SHOW_LISTING_ERROR';

export const QUERY_LISTINGS_REQUEST =
  'app/StoreListingPage/QUERY_LISTINGS_REQUEST';
export const QUERY_LISTINGS_SUCCESS =
  'app/StoreListingPage/QUERY_LISTINGS_SUCCESS';
export const QUERY_LISTINGS_ERROR =
  'app/StoreListingPage/QUERY_LISTINGS_ERROR';

export const SEARCH_LISTINGS_REQUEST =
  'app/StoreListingPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS =
  'app/StoreListingPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR =
  'app/StoreListingPage/SEARCH_LISTINGS_ERROR';
// ================ Reducer ================ //

const initialState = {
  id: null,
  showListingError: null,
  listings: [],
  listingsMeta: {},
  queryListingsError: null,
  queryListingsInProgress: false,
  searchKeywords: '',
  searchListingsInProgress: false,
  searchListingsError: null,
};

const storeListingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SHOW_LISTING_REQUEST:
      return {
        ...state,
        id: null,
        showListingError: null,
        searchKeywords: '',
      };
    case SHOW_LISTING_SUCCESS:
      return {
        ...state,
        id: payload.listingId,
      };
    case SHOW_LISTING_ERROR:
      return {
        ...state,
        id: null,
        showListingError: payload,
      };

    case QUERY_LISTINGS_REQUEST:
      return {
        ...state,
        queryListingsError: null,
        queryListingsInProgress: true,
      };
    case QUERY_LISTINGS_SUCCESS:
      return {
        ...state,
        listings: uniqBy([...state.listings, ...payload.listings], 'id.uuid'),
        queryListingsInProgress: false,
        listingsMeta: payload.meta,
      };
    case QUERY_LISTINGS_ERROR:
      return {
        ...state,
        queryListingsError: payload,
        queryListingsInProgress: false,
      };

    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        searchKeywords: payload.params,
        listings: [],
        listingsMeta: {},
        searchListingsInProgress: true,
        searchListingsError: null,
      };
    case SEARCH_LISTINGS_SUCCESS: {
      const { listings, meta } = payload;

      return {
        ...state,
        searchListingsInProgress: false,
        listings,
        listingsMeta: meta,
      };
    }
    case SEARCH_LISTINGS_ERROR:
      return {
        ...state,
        searchListingsInProgress: false,
        searchListingsError: payload,
      };

    default:
      return state;
  }
};

export default storeListingPageReducer;

// ================ Action creators ================ //

const requestAction = actionType => params => ({ type: actionType, payload: { params } });

const successAction = actionType => result => ({ type: actionType, payload: result.data });

const errorAction = actionType => error => ({ type: actionType, payload: error });

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const showListingRequest = () => ({
  type: SHOW_LISTING_REQUEST,
});
export const showListingSuccess = payload => ({
  type: SHOW_LISTING_SUCCESS,
  payload,
});
export const showListingError = e => ({
  type: SHOW_LISTING_ERROR,
  error: true,
  payload: e,
});

export const queryListingsRequest = requestAction(
  QUERY_LISTINGS_REQUEST
);
export const queryListingsSuccess = successAction(
  QUERY_LISTINGS_SUCCESS
);
export const queryListingsError = errorAction(
  QUERY_LISTINGS_ERROR
);

export const searchListingsRequest = requestAction(
  SEARCH_LISTINGS_REQUEST
);
export const searchListingsSuccess = successAction(
  SEARCH_LISTINGS_SUCCESS
);
export const searchListingsError = errorAction(
  SEARCH_LISTINGS_ERROR
);

// ================ Thunks ================ //

export const searchListings = ({ authorId, keywords, config }) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(searchListingsRequest(keywords));

  const imageVariantInfo = getImageVariantInfo(config.listing);

  const queryParams = {
    authorId,
    keywords,
    pub_listingType: JH_LISTING_TYPES.PRODUCT_LISTING,
    page: 1,
    per_page: ITEM_PER_PAGE,
    include: ['images', 'author'],
    'fields.image': imageVariantInfo.fieldsImage,
    ...imageVariantInfo.imageVariants,
  };

  return sdk.listings
    .query(queryParams)
    .then(response => {
      const entities = denormalisedResponseEntities(response);

      dispatch(
        searchListingsSuccess({
          data: {
            listings: entities,
            meta: response.data.meta,
          },
        })
      );
      return entities;
    })
    .catch(e => {
      dispatch(searchListingsError(e));
    });
};

export const showListing = ({
  config,
  isOwn = false,
  storeSlug,
  currentUserId,
}) => (dispatch, getState, sdk) => {
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  dispatch(showListingRequest());
  const params = {
    pub_storeName: storeSlug,
    pub_listingType: JH_LISTING_TYPES.STORE_LISTING,
    include: ['images', 'author'],
    'fields.image': [
      // Scaled variants for large images
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      // Cropped variants for listing thumbnail images
      `variants.${variantPrefix}`,
      `variants.${variantPrefix}-2x`,
      `variants.${variantPrefix}-4x`,
      `variants.${variantPrefix}-6x`,

      // Social media
      'variants.facebook',
      'variants.twitter',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-4x`, 1600, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-6x`, 2400, aspectRatio),
  };

  const show = isOwn
    ? queryOwnListingByAuthorId(currentUserId)
    : sdk.listings.query;

  return show(params)
    .then(response => {
      const [currentListing] = denormalisedResponseEntities(response);

      const listingFields = config?.listing?.listingFields;
      const sanitizeConfig = { listingFields };
      dispatch(showListingSuccess({ listingId: currentListing.id.uuid }));
      dispatch(addMarketplaceEntities(response, sanitizeConfig));
      return currentListing;
    })
    .catch(e => {
      dispatch(showListingError(storableError(e)));
    });
};

const queryListings = ({ authorId, page = 1, config }) => (
  dispatch,
  getState,
  sdk
) => {
  const {
    searchKeywords
  } = getState().StoreListingPage;
  dispatch(queryListingsRequest({}));
  const imageVariantInfo = getImageVariantInfo(config.listing);

  const queryParams = {
    authorId,
    ...(searchKeywords ? { keywords: searchKeywords } : {}),
    include: ['images', 'author'],
    'fields.image': imageVariantInfo.fieldsImage,
    ...imageVariantInfo.imageVariants,
    page,
    per_page: ITEM_PER_PAGE,
    pub_listingType: JH_LISTING_TYPES.PRODUCT_LISTING,
  };

  return sdk.listings
    .query(queryParams)
    .then(response => {
      const entities = denormalisedResponseEntities(response);

      dispatch(
        queryListingsSuccess({
          data: {
            listings: entities,
            meta: response.data.meta,
          },
        })
      );
      return entities;
    })
    .catch(e => {
      dispatch(queryListingsError(e));
    });
};

export const loadMoreListings = params => dispatch => {
  return dispatch(queryListings(params));
};

export const loadData = (params, search, config) => dispatch => {
  const { storeSlug } = params;

  // Clear old line-items
  dispatch(setInitialValues({ lineItems: null }));

  const ownListingVariants = [
    LISTING_PAGE_DRAFT_VARIANT,
    LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  ];

  return dispatch(fetchCurrentUser()).then(res => {
    if (ownListingVariants.includes(params.variant)) {
      const authorId = res.id.uuid;
      return dispatch(
        showListing({
          config,
          isOwn: true,
          storeSlug,
          currentUserId: authorId,
        })
      );
    }

    return dispatch(showListing({ config, storeSlug }));
  }).then(currentListing => {
  return dispatch(queryListings({ authorId: currentListing.author.id.uuid, config }))
  });
};
