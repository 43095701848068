import React from 'react';
import loadable from '@loadable/component';

import getPageDataLoadingAPI from '../containers/pageDataLoadingAPI';
import NotFoundPage from '../containers/NotFoundPage/NotFoundPage';
import PreviewResolverPage from '../containers/PreviewResolverPage/PreviewResolverPage';

import extensionRoutes from '../extensions/routes';

// routeConfiguration needs to initialize containers first
// Otherwise, components will import form container eventually and
// at that point css bundling / imports will happen in wrong order.
import { NamedRedirect } from '../components';
import { USER_TYPE } from '../util/types';

const { BUYER, SELLER } = USER_TYPE;
const pageDataLoadingAPI = getPageDataLoadingAPI();

const AuthenticationPage = loadable(() => import(/* webpackChunkName: "AuthenticationPage" */ '../containers/AuthenticationPage/AuthenticationPage'));
const CheckoutPage = loadable(() => import(/* webpackChunkName: "CheckoutPage" */ '../containers/CheckoutPage/CheckoutPage'));
const CMSPage = loadable(() => import(/* webpackChunkName: "CMSPage" */ '../containers/CMSPage/CMSPage'));
const ContactDetailsPage = loadable(() => import(/* webpackChunkName: "ContactDetailsPage" */ '../containers/ContactDetailsPage/ContactDetailsPage'));
const CartPage = loadable(() => import(/* webpackChunkName: "CartPage" */ '../containers/CartPage/CartPage'));
const EditListingPage = loadable(() => import(/* webpackChunkName: "EditListingPage" */ '../containers/EditListingPage/EditListingPage'));
const SellerOnboardingPage = loadable(() => import(/* webpackChunkName: "SellerOnboardingPage" */ '../containers/SellerOnboardingPage/SellerOnboardingPage'));
const EmailVerificationPage = loadable(() => import(/* webpackChunkName: "EmailVerificationPage" */ '../containers/EmailVerificationPage/EmailVerificationPage'));
const InboxPage = loadable(() => import(/* webpackChunkName: "InboxPage" */ '../containers/InboxPage/InboxPage'));
// const LandingPage = loadable(() => import(/* webpackChunkName: "LandingPage" */ '../containers/LandingPage/LandingPage'));
const ListingPageCarousel = loadable(() => import(/* webpackChunkName: "ListingPageCarousel" */ /* webpackPrefetch: true */ '../containers/ListingPage/ListingPageCarousel'));
const ManageListingsPage = loadable(() => import(/* webpackChunkName: "ManageListingsPage" */ '../containers/ManageListingsPage/ManageListingsPage'));
const PasswordChangePage = loadable(() => import(/* webpackChunkName: "PasswordChangePage" */ '../containers/PasswordChangePage/PasswordChangePage'));
const PasswordRecoveryPage = loadable(() => import(/* webpackChunkName: "PasswordRecoveryPage" */ '../containers/PasswordRecoveryPage/PasswordRecoveryPage'));
const PasswordResetPage = loadable(() => import(/* webpackChunkName: "PasswordResetPage" */ '../containers/PasswordResetPage/PasswordResetPage'));
const PaymentMethodsPage = loadable(() => import(/* webpackChunkName: "PaymentMethodsPage" */ '../containers/PaymentMethodsPage/PaymentMethodsPage'));
const PrivacyPolicyPage = loadable(() => import(/* webpackChunkName: "PrivacyPolicyPage" */ '../containers/PrivacyPolicyPage/PrivacyPolicyPage'));
const ProfilePage = loadable(() => import(/* webpackChunkName: "ProfilePage" */ '../containers/ProfilePage/ProfilePage'));
const ProfileSettingsPage = loadable(() => import(/* webpackChunkName: "ProfileSettingsPage" */ '../containers/ProfileSettingsPage/ProfileSettingsPage'));
const AwsBedrockAnalyzeImage = loadable(() => import(/* webpackChunkName: "ProfileSettingsPage" */ '../containers/AwsBedrockAnalyzeImage/AwsBedrockAnalyzeImage'));
const AwsBedrockAnalyzeImageRAG = loadable(() => import(/* webpackChunkName: "ProfileSettingsPage" */ '../containers/AwsBedrockAnalyzeImageRAG/AwsBedrockAnalyzeImage'));
const SearchPageWithGrid = loadable(() => import(/* webpackChunkName: "SearchPageWithGrid" */ /* webpackPrefetch: true */  '../containers/SearchPage/SearchPageWithGrid'));
const StripePayoutPage = loadable(() => import(/* webpackChunkName: "StripePayoutPage" */ '../containers/StripePayoutPage/StripePayoutPage'));
const TermsOfServicePage = loadable(() => import(/* webpackChunkName: "TermsOfServicePage" */ '../containers/TermsOfServicePage/TermsOfServicePage'));
const TransactionPage = loadable(() => import(/* webpackChunkName: "TransactionPage" */ '../containers/TransactionPage/TransactionPage'));
const ImportListingsPage = loadable(() => import(/* webpackChunkName: "TransactionPage" */ '../containers/ImportListingsPage/ImportListingsPage'));
const GuestCheckoutPage = loadable(() => import(/* webpackChunkName: "GuestCheckoutPage" */ '../containers/GuestCheckoutPage/GuestCheckoutPage'));
const StoreListingPage = loadable(() => import(/* webpackChunkName: "ListingPageCarousel" */ /* webpackPrefetch: true */ '../containers/StoreListingPage/StoreListingPage'));
const BecomeASellerStaticPage = loadable(() => import(/* webpackChunkName: "ListingPageCarousel" */ /* webpackPrefetch: true */ '../containers/BecomeASellerStaticPage/BecomeASellerStaticPage'));
const TheMovementStaticPage = loadable(() => import(/* webpackChunkName: "ListingPageCarousel" */ /* webpackPrefetch: true */ '../containers/BecomeASellerStaticPage/TheMovementStaticPage'));
const OurCommitmentStaticPage = loadable(() => import(/* webpackChunkName: "ListingPageCarousel" */ /* webpackPrefetch: true */ '../containers/BecomeASellerStaticPage/OurCommitmentStaticPage'));
const VillageCouncilStaticPage = loadable(() => import(/* webpackChunkName: "ListingPageCarousel" */ /* webpackPrefetch: true */ '../containers/BecomeASellerStaticPage/VillageCouncilStaticPage'));
const OurStoryStaticPage = loadable(() => import(/* webpackChunkName: "ListingPageCarousel" */ /* webpackPrefetch: true */ '../containers/BecomeASellerStaticPage/OurStoryStaticPage'));
const LandingPage = loadable(() => import(/* webpackChunkName: "LandingPage" */ '../containers/HomePageCustom/HomePageCustom'));
const BuyerFAQStaticPage = loadable(() => import(/* webpackChunkName: "BuyerFAQStaticPage" */ '../containers/SellersStaticPage/BuyerFAQStaticPage'));
const SellerFAQStaticPage = loadable(() => import(/* webpackChunkName: "SellerFAQStaticPage" */ '../containers/SellersStaticPage/SellerFAQStaticPage'));
const SellerRateSheetStaticPage = loadable(() => import(/* webpackChunkName: "SellerRateSheetStaticPage" */ '../containers/SellersStaticPage/SellerRateSheetStaticPage'));
const PartnersStaticPage = loadable(() => import(/* webpackChunkName: "PartnersStaticPage" */ '../containers/PartnersStaticPage/PartnersStaticPage'));
const MakerListingPage = loadable(() => import(/* webpackChunkName: "MakerListingPage" */ '../containers/MakerListingPage/MakerListingPage'));
const SellerDashboardPage = loadable(() => import(/* webpackChunkName: "SellerDashboardPage" */ '../containers/SellerDashboardPage/SellerDashboardPage'));
const SellerEthicalPledgePage = loadable(() => import(/* webpackChunkName: "SellerEthicalPledgePage" */ '../containers/SellerEthicalPledgePage/SellerEthicalPledgePage'));
const EditArtisanPage = loadable(() => import(/* webpackChunkName: "EditArtisanPage" */ '../extensions/Artisans/container/EditArtisansPage/EditArtisanPage'));
// Styleguide helps you to review current components and develop new ones
const StyleguidePage = loadable(() => import(/* webpackChunkName: "StyleguidePage" */ '../containers/StyleguidePage/StyleguidePage'));

const ExternalSessionPage = loadable(() => import(/* webpackChunkName: "ExternalSessionPage" */ '../containers/ExternalSessionPage/ExternalSessionPage'));

export const ACCOUNT_SETTINGS_PAGES = [
  'ContactDetailsPage',
  'PasswordChangePage',
  'StripePayoutPage',
  'PaymentMethodsPage',
];

// https://en.wikipedia.org/wiki/Universally_unique_identifier#Nil_UUID
const draftId = '00000000-0000-0000-0000-000000000000';
const draftSlug = 'draft';

const RedirectToLandingPage = () => <NamedRedirect name="LandingPage" />;

// NOTE: Most server-side endpoints are prefixed with /api. Requests to those
// endpoints are indended to be handled in the server instead of the browser and
// they will not render the application. So remember to avoid routes starting
// with /api and if you encounter clashing routes see server/index.js if there's
// a conflicting route defined there.

// Our routes are exact by default.
// See behaviour from Routes.js where Route is created.
const routeConfiguration = (layoutConfig) => {
  const SearchPage = SearchPageWithGrid;
  const ListingPage = ListingPageCarousel;

  return [
    {
      path: '/',
      name: 'LandingPage',
      component: LandingPage,
      loadData: pageDataLoadingAPI.LandingPage.loadData,
    },
    {
      path: '/page/:pageId',
      name: 'CMSPage',
      component: CMSPage,
      loadData: pageDataLoadingAPI.CMSPage.loadData,
    },
    {
      path: '/search',
      name: 'SearchPage',
      component: SearchPage,
      loadData: pageDataLoadingAPI.SearchPage.loadData,
    },
    {
      path: '/sellers/products/import',
      name: 'ImportListingsPage',
      auth: true,
      authPage: 'LoginPage',
      authorizeFor: [SELLER],
      component: ImportListingsPage,
      loadData: pageDataLoadingAPI.ImportListingsPage.loadData,
    },
    {
      path: '/p',
      name: 'ListingBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/p/:id',
      name: 'ShortURLListingPage',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/p/:id/:productSlug',
      name: 'ListingPage',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/p/:slug/:id/checkout',
      name: 'CheckoutPage',
      auth: true,
      component: CheckoutPage,
      setInitialValues: pageDataLoadingAPI.CheckoutPage.setInitialValues,
    },
    {
      path: '/p/:slug/:id/guest-checkout',
      name: 'GuestCheckoutPage',
      component: GuestCheckoutPage,
      setInitialValues: pageDataLoadingAPI.GuestCheckoutPage.setInitialValues,
    },
    {
      path: '/p/:id/:productSlug/:variant',
      name: 'ListingPageVariant',
      auth: true,
      authPage: 'LoginPage',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    // {
    //   path: '/onboarding/:slug/:id/:type/:tab',
    //   name: 'SellerOnboardingPage',
    //   auth: true,
    //   component: SellerOnboardingPage,
    //   loadData: pageDataLoadingAPI.SellerOnboardingPage.loadData,
    // },
    // {
    //   path: '/onboarding/:slug/:id/:type/:tab/:returnURLType',
    //   name: 'SellerStripeOnboardingPage',
    //   auth: true,
    //   component: SellerOnboardingPage,
    //   loadData: pageDataLoadingAPI.SellerOnboardingPage.loadData,
    // },
    // ...['/sellers/onboarding', '/sellers/onboarding/ethical-pledge'].map(path => (
    //   {
    //     path: '/sellers/onboarding',
    //     name: 'SellerOnboardingPage',
    //     auth: true,
    //     authPage: 'LoginPage',
    //     component: SellerEthicalPledgePage,
    //     loadData: pageDataLoadingAPI.SellerEthicalPledgePage.loadData,
    //   })),
    {
      path: '/sellers/onboarding',
      name: 'SellerOnboardingStartPage',
      auth: true,
      authPage: 'LoginPage',
      component: SellerEthicalPledgePage,
      loadData: pageDataLoadingAPI.SellerEthicalPledgePage.loadData,
    },
    {
      path: '/sellers/onboarding/ethical-pledge',
      name: 'SellerEthicalPledgePage',
      auth: true,
      authPage: 'LoginPage',
      component: SellerEthicalPledgePage,
      loadData: pageDataLoadingAPI.SellerEthicalPledgePage.loadData,
    },
    {
      path: '/sellers/onboarding/products',
      name: 'SellerOnboardingListingsPage',
      auth: true,
      authorizeFor: [SELLER],
      authPage: 'LoginPage',
      component: ManageListingsPage,
      loadData: pageDataLoadingAPI.ManageListingsPage.loadData,
    },
    {
      path: '/sellers/onboarding/:tab',
      name: 'SellerOnboardingPage',
      auth: true,
      component: SellerOnboardingPage,
      loadData: pageDataLoadingAPI.SellerOnboardingPage.loadData,
    },
    {
      path: '/sellers/onboarding/:tab/:returnURLType',
      name: 'SellerStripeOnboardingPage',
      auth: true,
      component: SellerOnboardingPage,
      loadData: pageDataLoadingAPI.SellerOnboardingPage.loadData,
    },
    {
      path: '/sellers/store-details',
      name: 'EditShopPage',
      auth: true,
      authorizeFor: [SELLER],
      authPage: 'LoginPage',
      component: SellerOnboardingPage,
      loadData: pageDataLoadingAPI.SellerOnboardingPage.loadData,
    },
    {
      path: '/sellers/products/new',
      name: 'NewListingPage',
      auth: true,
      component: () => (
        <NamedRedirect
          name="EditListingPage"
          params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'overview' }}
        />
      ),
    },
    {
      path: '/sellers/products/edit/:slug/:id/:type/:tab',
      name: 'EditListingPage',
      auth: true,
      authorizeFor: [SELLER],
      component: EditListingPage,
      loadData: pageDataLoadingAPI.EditListingPage.loadData,
    },
    {
      path: '/sellers/artisans/new',
      name: 'NewArtisanPage',
      auth: true,
      component: () => (
        <NamedRedirect
          name="EditArtisanPage"
          params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'details' }}
        />
      ),
    },
    {
      path: '/sellers/artisans/edit/:slug/:id/:type/:tab',
      name: 'EditArtisanPage',
      auth: true,
      authorizeFor: [SELLER],
      component: EditArtisanPage,
      loadData: pageDataLoadingAPI.EditArtisanPage.loadData,
    },
    {
      path: '/l/:slug/:id/:type/:tab/:returnURLType',
      name: 'EditListingStripeOnboardingPage',
      auth: true,
      authorizeFor: [SELLER],
      component: EditListingPage,
      loadData: pageDataLoadingAPI.EditListingPage.loadData,
    },

    // Canonical path should be after the `/l/new` path since they
    // conflict and `new` is not a valid listing UUID.
    {
      path: '/l/:id',
      name: 'ListingPageCanonical',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/u',
      name: 'ProfileBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/u/:id',
      name: 'ProfilePage',
      component: ProfilePage,
      loadData: pageDataLoadingAPI.ProfilePage.loadData,
    },
    {
      path: '/account/profile',
      name: 'ProfileSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ProfileSettingsPage,
    },
    {
      path: '/upload-product',
      name: 'AwsBedrockAnalyzeImage',
      component: AwsBedrockAnalyzeImage,
    },
    {
      path: '/analyze-product',
      name: 'AwsBedrockAnalyzeImageRAG',
      component: AwsBedrockAnalyzeImageRAG,
    },

    // Note: authenticating with IdP (e.g. Facebook) expects that /login path exists
    // so that in the error case users can be redirected back to the LoginPage
    // In case you change this, remember to update the route in server/api/auth/loginWithIdp.js
    {
      path: '/login',
      name: 'LoginPage',
      component: AuthenticationPage,
      extraProps: { tab: 'login' },
      loadData: pageDataLoadingAPI.AuthenticationPage.loadData,
    },
    {
      path: '/seller/login',
      name: 'SellerLoginPage',
      component: AuthenticationPage,
      extraProps: { tab: 'login', role: SELLER },
      loadData: pageDataLoadingAPI.SellerLoginPage.loadData,
    },
    {
      path: '/signup',
      name: 'SignupPage',
      component: AuthenticationPage,
      extraProps: { tab: 'signup', role: BUYER },
      loadData: pageDataLoadingAPI.AuthenticationPage.loadData,
    },
    {
      path: '/seller/signup',
      name: 'SellerSignupPage',
      component: AuthenticationPage,
      extraProps: { tab: 'signup', role: SELLER },
      loadData: pageDataLoadingAPI.AuthenticationPage.loadData,
    },
    {
      path: '/seller/confirm',
      name: 'SellerConfirmPage',
      component: AuthenticationPage,
      extraProps: { tab: 'confirm', role: SELLER },
    },
    {
      path: '/confirm',
      name: 'ConfirmPage',
      component: AuthenticationPage,
      extraProps: { tab: 'confirm' },
    },
    {
      path: '/recover-password',
      name: 'PasswordRecoveryPage',
      component: PasswordRecoveryPage,
    },
    ...['/sellers', '/sellers/dashboard'].map(path => (
      {
        path,
        name: 'SellerDashboardPage',
        auth: true,
        authPage: 'LoginPage',
        component: SellerDashboardPage,
        loadData: pageDataLoadingAPI.SellerDashboardPage.loadData,
      })),
    {
      path: '/v1/account/orders/detail/:id',
      name: 'OrderDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: TransactionPage,
      extraProps: { transactionRole: 'customer' },
      loadData: (params, ...rest) =>
        pageDataLoadingAPI.TransactionPage.loadData({ ...params, transactionRole: 'customer' }, ...rest),
      setInitialValues: pageDataLoadingAPI.TransactionPage.setInitialValues,
    },
    {
      path: '/order/:id',
      name: 'OrderDetailsPageRedirect',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="OrderDetailsPage" params={{ id: props.params?.id }} />,
    },
    {
      path: '/v1/sellers/orders/detail/:id',
      name: 'SaleDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: TransactionPage,
      extraProps: { transactionRole: 'provider' },
      loadData: pageDataLoadingAPI.TransactionPage.loadData,
    },
    {
      path: '/sale/:id',
      name: 'SaleDetailsPageRedirect',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="SaleDetailsPage" params={{ id: props.params?.id }} />,
    },
    {
      path: '/sellers/listings',
      name: 'ManageListingsPage',
      auth: true,
      authorizeFor: [SELLER],
      authPage: 'LoginPage',
      component: ManageListingsPage,
      loadData: pageDataLoadingAPI.ManageListingsPage.loadData,
    },
    {
      path: '/account',
      name: 'AccountSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="ContactDetailsPage" />,
    },
    {
      path: '/account/contact-details',
      name: 'ContactDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ContactDetailsPage,
      loadData: pageDataLoadingAPI.ContactDetailsPage.loadData,
    },
    {
      path: '/account/change-password',
      name: 'PasswordChangePage',
      auth: true,
      authPage: 'LoginPage',
      component: PasswordChangePage,
    },
    {
      path: '/sellers/payout-account',
      name: 'StripePayoutPage',
      auth: true,
      authorizeFor: [SELLER],
      authPage: 'LoginPage',
      component: StripePayoutPage,
      loadData: pageDataLoadingAPI.StripePayoutPage.loadData,
    },
    {
      path: '/account/payments/:returnURLType',
      name: 'StripePayoutOnboardingPage',
      auth: true,
      authorizeFor: [SELLER],
      authPage: 'LoginPage',
      component: StripePayoutPage,
      loadData: pageDataLoadingAPI.StripePayoutPage.loadData,
    },
    {
      path: '/account/payment-methods',
      name: 'PaymentMethodsPage',
      auth: true,
      authPage: 'LoginPage',
      component: PaymentMethodsPage,
      loadData: pageDataLoadingAPI.PaymentMethodsPage.loadData,
    },
    {
      path: '/sellers/payment-methods',
      name: 'SellerPaymentMethodsPage',
      auth: true,
      authorizeFor: [SELLER],
      authPage: 'LoginPage',
      component: PaymentMethodsPage,
      loadData: pageDataLoadingAPI.PaymentMethodsPage.loadData,
    },
    {
      path: '/inbox',
      name: 'InboxPage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="BuyerOrdersPage" />,
    },
    {
      path: '/sellers/orders',
      name: 'SellerOrdersPage',
      auth: true,
      authPage: 'LoginPage',
      component: InboxPage,
      loadData: pageDataLoadingAPI.InboxPage.loadData,
    },
    {
      path: '/account/orders',
      name: 'BuyerOrdersPage',
      auth: true,
      authPage: 'LoginPage',
      component: InboxPage,
      loadData: pageDataLoadingAPI.InboxPage.loadData,
    },
    {
      path: '/terms-of-service',
      name: 'TermsOfServicePage',
      component: TermsOfServicePage,
      loadData: pageDataLoadingAPI.TermsOfServicePage.loadData,
    },
    {
      path: '/privacy-policy',
      name: 'PrivacyPolicyPage',
      component: PrivacyPolicyPage,
      loadData: pageDataLoadingAPI.PrivacyPolicyPage.loadData,
    },
    {
      path: '/styleguide',
      name: 'Styleguide',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/g/:group',
      name: 'StyleguideGroup',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component',
      name: 'StyleguideComponent',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component/:example',
      name: 'StyleguideComponentExample',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component/:example/raw',
      name: 'StyleguideComponentExampleRaw',
      component: StyleguidePage,
      extraProps: { raw: true },
    },
    {
      path: '/cart',
      name: 'CartPage',
      component: CartPage,
      loadData: pageDataLoadingAPI.CartPage.loadData,
    },
    {
      path: '/cart/checkout',
      name: 'CartCheckoutPage',
      auth: true,
      authPage: 'LoginPage',
      component: CheckoutPage,
      setInitialValues: pageDataLoadingAPI.CheckoutPage.setInitialValues,
    },
    {
      path: '/cart/guest-checkout',
      name: 'GuestCartCheckoutPage',
      component: GuestCheckoutPage,
      setInitialValues: pageDataLoadingAPI.GuestCheckoutPage.setInitialValues,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /reset-password endpoint
    {
      path: '/reset-password',
      name: 'PasswordResetPage',
      component: PasswordResetPage ,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /verify-email endpoint
    {
      path: '/verify-email',
      name: 'EmailVerificationPage',
      auth: true,
      authPage: 'LoginPage',
      component: EmailVerificationPage,
      loadData: pageDataLoadingAPI.EmailVerificationPage.loadData,
    },
    // Do not change this path!
    //
    // The API expects that the application implements /preview endpoint
    {
      path: '/preview',
      name: 'PreviewResolverPage',
      component: PreviewResolverPage ,
    },
    {
      path: '/shop/:cloudTag',
      name: 'CloudTagPage',
      component: SearchPage,
      extraProps: { currentPageName: "CloudTagPage" },
      loadData: pageDataLoadingAPI.SearchPage.loadData,
    }, {
      path: '/store/:storeSlug',
      name: 'StoreListingPage',
      component: StoreListingPage,
      loadData: pageDataLoadingAPI.StoreListingPage.loadData,
    },
    {
      path: '/store/:storeSlug/:variant',
      name: 'StoreListingPageVariant',
      auth: true,
      authPage: 'LoginPage',
      component: StoreListingPage,
      loadData: pageDataLoadingAPI.StoreListingPage.loadData,
    },
    {
      path: '/m/:makerListingId',
      name: 'MakerListingPage',
      component: MakerListingPage,
      loadData: pageDataLoadingAPI.MakerListingPage.loadData,
    },
    {
      path: '/c/:topLevelCategory',
      name: 'CategoryPage',
      component: SearchPage,
      extraProps: { currentPageName: "CategoryPage" },
      loadData: pageDataLoadingAPI.SearchPage.loadData,
    },
    {
      path: '/c/:topLevelCategory/:parentCategory',
      name: 'CategoryPageWithParent',
      component: SearchPage,
      extraProps: { currentPageName: "CategoryPageWithParent" },
      loadData: pageDataLoadingAPI.SearchPage.loadData,
    },
    {
      path: '/c/:topLevelCategory/:parentCategory/:productType',
      name: 'CategoryPageWithProductType',
      component: SearchPage,
      extraProps: { currentPageName: "CategoryPageWithProductType" },
      loadData: pageDataLoadingAPI.SearchPage.loadData,
    },
    {
      path: '/become-a-seller',
      name: 'BecomeASellerStaticPage',
      component: props => <BecomeASellerStaticPage {...props} />,
    },
    {
      path: '/about-us/the-movement',
      name: 'TheMovementStaticPage',
      component: props => <TheMovementStaticPage {...props} />,
    },
    {
      path: '/about-us/our-commitment',
      name: 'OurCommitmentStaticPage',
      component: props => <OurCommitmentStaticPage {...props} />,
    },
    {
      path: '/about-us/village-council',
      name: 'VillageCouncilStaticPage',
      component: props => <VillageCouncilStaticPage {...props} />,
    },
    {
      path: '/about-us/our-story',
      name: 'OurStoryStaticPage',
      component: props => <OurStoryStaticPage {...props} />,
    },
    {
      path: '/buyers/faq',
      name: 'BuyerFAQStaticPage',
      component: BuyerFAQStaticPage,
      loadData: pageDataLoadingAPI.LandingPage.loadData,
    },
    {
      path: '/sellers/faq',
      name: 'SellerFAQStaticPage',
      component: SellerFAQStaticPage,
      loadData: pageDataLoadingAPI.LandingPage.loadData,
    },
    {
      path: '/sellers/rate-sheet',
      name: 'SellerRateSheetStaticPage',
      component: SellerRateSheetStaticPage,
      loadData: pageDataLoadingAPI.LandingPage.loadData,
    },
    {
      path: '/affiliates',
      name: 'PartnersStaticPage',
      component: PartnersStaticPage,
    },
    {
      path: '/external/cart-session',
      name: 'ExternalSessionPage',
      component: ExternalSessionPage,
    },
    {
      path: '/notfound',
      name: 'NotFoundPage',
      component: props => <NotFoundPage {...props} />,
    },
    ...extensionRoutes,
  ];
};

export default routeConfiguration;