import moment from 'moment';
import get from 'lodash/get';
import { ADMIN } from '../../constants';
import { unitDivisor } from '../../../../../util/currency';
import { FormattedText } from '../../../../../util/data';

export const DATE_RANGE_FORMAT = 'Do MMMM YYYY';
const CHART_DATE_FORMAT = 'Do MMMM';
const CHART_DATE_FORMAT_FROM_API = 'YYYY-MM-DDTHH:mm:ssZ';

import css from './AdminDashboardPage.module.css';

export const setDateRangeLastXDays = (days, form, event) => {
  if (!days || !form || !event) return;
  event.preventDefault();
  event.stopPropagation();
  const today = moment();
  const daysAgo = moment()
    .subtract(days, 'days')
    .startOf('day');

  form.change('dateRange', {
    startDate: daysAgo.toDate(),
    endDate: today.toDate(),
  });
};

export const setDateRangeToday = (form, event) => {
  if (!form || !event) return;
  event.preventDefault();
  event.stopPropagation();
  const today = moment();
  form.change('dateRange', {
    startDate: today.startOf('day').toDate(),
    endDate: today.toDate(),
  });
};

export const isUserAllowedToAccessAdminDashboard = user => {
  if (!user) return false;
  const adminRoles = get(user, 'attributes.profile.metadata.adminRoles', []);
  return adminRoles.includes(ADMIN);
};

const roundNumberToTwoDecimalPlaces = number => {
  if (typeof number !== 'number' || number % 1 === 0) return number;
  return number.toFixed(2);
};

export const prepareDataForDashboard = statistics => {
  if (!statistics) return null;

  const {
    unFulfilledOrders,
    liveProducts,
    totalOrders,
    revenue,
    revenueChartData,
    productSales,
  } = statistics;

  const revenueChartDataFormatted = revenueChartData.map(data => {
    const formattedData = {
      period: moment(data.period, CHART_DATE_FORMAT_FROM_API).format(
        CHART_DATE_FORMAT
      ),
    };
    if (Number.isInteger(data.value)) {
      formattedData.value = data.value / unitDivisor('USD');
    }
    if (Number.isInteger(data.compareValue)) {
      formattedData.compareValue = data.compareValue / unitDivisor('USD');
    }
    return formattedData;
  });

  const liveProductsObject = {
    ...liveProducts,
    label: <FormattedText tagName="p" id="AdminDashboardPage.liveProducts" />,
    percentageChange: roundNumberToTwoDecimalPlaces(
      liveProducts.percentageChange
    ),
  };

  const unFulfilledOrdersObject = {
    ...unFulfilledOrders,
    label: (
      <FormattedText tagName="p" id="AdminDashboardPage.unfulfilledOrders" />
    ),
    percentageChange: roundNumberToTwoDecimalPlaces(
      unFulfilledOrders.percentageChange
    ),
  };

  const totalOrdersObject = {
    label: <FormattedText tagName="p" id="AdminDashboardPage.totalOrders" />,
    ...totalOrders,
    percentageChange: roundNumberToTwoDecimalPlaces(
      totalOrders.percentageChange
    ),
  };

  const revenueObject = {
    ...revenue,
    label: (
      <FormattedText
        tagName="p"
        id="AdminDashboardPage.totalRevenue"
        values={{
          lineBreak: <br />,
          secondLine: (
            <FormattedText
              className={css.labelSecondLine}
              id="AdminDashboardPage.forShippedOrders"
            />
          ),
        }}
      />
    ),
    currency: 'USD',
    percentageChange: roundNumberToTwoDecimalPlaces(revenue.percentageChange),
  };

  return {
    liveProducts: liveProductsObject,
    unFulfilledOrders: unFulfilledOrdersObject,
    totalOrders: totalOrdersObject,
    revenue: revenueObject,
    revenueChartData: revenueChartDataFormatted,
  };
};

export const prepareRecentOrdersData = recentOrders => {
  if (!recentOrders) return null;

  return recentOrders.map(order => {
    const { saleRevenue, ...rest } = order;
    return {
      ...rest,
      saleRevenue: saleRevenue / unitDivisor('USD'),
    };
  });
};

export const prepareParamsForAPIRequest = ({
  startDate,
  endDate,
  compareStartDate,
  compareEndDate,
}) => {
  const formattedParams = {};

  if (startDate && endDate) {
    formattedParams.timeRange = `${startDate},${endDate}`;
  }

  if (compareStartDate && compareEndDate) {
    formattedParams.compareTimeRange = `${compareStartDate},${compareEndDate}`;
  }

  return formattedParams;
};

export const prepareParamsForRecentOrdersAPIRequest = ({
  startDate,
  endDate,
  offset,
}) => {
  const formattedParams = {};

  if (startDate && endDate) {
    formattedParams.timeRange = `${startDate},${endDate}`;
  }

  if (offset) {
    formattedParams.offset = offset;
  }

  return formattedParams;
};

/**
 * Prepares timestamps for a given time range and optionally for a comparison period.
 *
 * @param {Object} params - The parameters for the function.
 * @param {string} params.startDate - The start date of the time range in ISO 8601 format.
 * @param {string} params.endDate - The end date of the time range in ISO 8601 format.
 * @param {boolean} params.hasComparison - A flag indicating whether a comparison period should be calculated.
 * @param {('previousPeriod'|'samePeriodLastYear')} params.comparisonType - The type of comparison period to calculate.
 * @returns {Object} An object containing the start and end timestamps for the time range and, if requested, the comparison period.
 * @returns {string} .startDate - The start timestamp of the time range in ISO 8601 format.
 * @returns {string} .endDate - The end timestamp of the time range in ISO 8601 format.
 * @returns {string} [.compareStartDate] - The start timestamp of the comparison period in ISO 8601 format. Only present if `hasComparison` is true.
 * @returns {string} [.compareEndDate] - The end timestamp of the comparison period in ISO 8601 format. Only present if `hasComparison` is true.
 * @returns {('previousPeriod'|'samePeriodLastYear')} .comparisonType - The type of comparison period that was calculated. Only present if `hasComparison` is true.
 */
export const prepareTimeRangeTimestamps = ({
  startDate,
  endDate,
  hasComparison,
  comparisonType,
}) => {
  const startTimestamp = moment(startDate)
    .startOf('day')
    .utc()
    .format();
  // if endTimeStamp is also the same date on the local device, we should use the current time
  // otherwise we should use the end of the day
  const endTimestamp = moment(endDate).isSame(moment(), 'day')
    ? moment()
        .utc()
        .format()
    : moment(endDate)
        .endOf('day')
        .utc()
        .format();

  const comparisonDateGenerator = {
    previousPeriod: () => {
      const duration = moment(endTimestamp).diff(moment(startTimestamp));
      return {
        compareStartDate: moment(startTimestamp)
          .subtract(duration, 'milliseconds')
          .startOf('day')
          .utc()
          .format(),
        compareEndDate: moment(startTimestamp)
          .subtract(1, 'days')
          .endOf('day')
          .utc()
          .format(),
      };
    },
    samePeriodLastYear: () => {
      return {
        compareStartDate: moment(startTimestamp)
          .subtract(1, 'year')
          .startOf('day')
          .utc()
          .format(),
        compareEndDate: moment(endTimestamp)
          .subtract(1, 'year')
          .endOf('day')
          .utc()
          .format(),
      };
    },
  };

  const comparisonDates = hasComparison
    ? comparisonDateGenerator[comparisonType]()
    : { compareStartDate: null, compareEndDate: null };

  return {
    startDate: startTimestamp,
    endDate: endTimestamp,
    ...comparisonDates,
    comparisonType,
  };
};
