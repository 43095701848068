import pick from 'lodash/pick';

import {
  transactionLineItems,
  validateCoupon,
  checkEmailExists,
  verifyEmail,
  initiateTransaction,
  requestTransactionPayment,
  confirmTransactionPayment,
  initiateChildTransaction,
} from '../../api';
import { statesUS as stateOptions } from '../../../../config/configStates';
import {
  denormalisedResponseEntities,
  getLabelByKey,
} from '../../../../util/data';
import { storableError } from '../../../../util/errors';
import * as log from '../../../../util/log';
import {
  fetchCurrentUser,
  fetchCurrentUserHasOrdersSuccess,
} from '../../../../ducks/user.duck';
import { login } from '../../../../ducks/auth.duck';
import { ADDRESS_COMFIRMED, USER_TYPE } from '../../../../util/types';
import { validateAddress } from '../../../googleMaps/api';
import { transitions as cartChildTransitions } from '../../transactions/transactionProcessCartChild';
import { validateAddressByShippo } from '../../../../util/api';

const ADMIN_LISTING_ID = process.env.REACT_APP_ADMIN_LISTING_ID;

// ================ Action types ================ //

export const SET_INITIAL_VALUES =
  'app/ShoppingCartCheckoutPage/SET_INITIAL_VALUES';

export const INITIATE_ORDER_REQUEST =
  'app/ShoppingCartCheckoutPage/INITIATE_ORDER_REQUEST';
export const INITIATE_ORDER_SUCCESS =
  'app/ShoppingCartCheckoutPage/INITIATE_ORDER_SUCCESS';
export const INITIATE_ORDER_ERROR =
  'app/ShoppingCartCheckoutPage/INITIATE_ORDER_ERROR';

export const REQUEST_ORDER_PAYMENT_REQUEST =
  'app/ShoppingCartCheckoutPage/REQUEST_ORDER_PAYMENT_REQUEST';
export const REQUEST_ORDER_PAYMENT_SUCCESS =
  'app/ShoppingCartCheckoutPage/REQUEST_ORDER_PAYMENT_SUCCESS';
export const REQUEST_ORDER_PAYMENT_ERROR =
  'app/ShoppingCartCheckoutPage/REQUEST_ORDER_PAYMENT_ERROR';

export const CONFIRM_PAYMENT_REQUEST =
  'app/ShoppingCartCheckoutPage/CONFIRM_PAYMENT_REQUEST';
export const CONFIRM_PAYMENT_SUCCESS =
  'app/ShoppingCartCheckoutPage/CONFIRM_PAYMENT_SUCCESS';
export const CONFIRM_PAYMENT_ERROR =
  'app/ShoppingCartCheckoutPage/CONFIRM_PAYMENT_ERROR';

export const STRIPE_CUSTOMER_REQUEST =
  'app/ShoppingCartCheckoutPage/STRIPE_CUSTOMER_REQUEST';
export const STRIPE_CUSTOMER_SUCCESS =
  'app/ShoppingCartCheckoutPage/STRIPE_CUSTOMER_SUCCESS';
export const STRIPE_CUSTOMER_ERROR =
  'app/ShoppingCartCheckoutPage/STRIPE_CUSTOMER_ERROR';

export const CHECK_DISCOUNT_CODE_REQUEST =
  'app/ShoppingCartCheckoutPage/CHECK_DISCOUNT_CODE_REQUEST';
export const CHECK_DISCOUNT_CODE_SUCCESS =
  'app/ShoppingCartCheckoutPage/CHECK_DISCOUNT_CODE_SUCCESS';
export const CHECK_DISCOUNT_CODE_ERROR =
  'app/ShoppingCartCheckoutPage/CHECK_DISCOUNT_CODE_ERROR';

export const REMOVE_DISCOUNT_CODE_DATA =
  'app/ShoppingCartCheckoutPage/REMOVE_DISCOUNT_CODE_DATA';

export const FETCH_LINE_ITEMS_REQUEST =
  'app/ShoppingCartCheckoutPage/FETCH_LINE_ITEMS_REQUEST';
export const FETCH_LINE_ITEMS_SUCCESS =
  'app/ShoppingCartCheckoutPage/FETCH_LINE_ITEMS_SUCCESS';
export const FETCH_LINE_ITEMS_ERROR =
  'app/ShoppingCartCheckoutPage/FETCH_LINE_ITEMS_ERROR';

export const CHECK_EMAIL_EXISTS_REQUEST =
  'app/ShoppingCartCheckoutPage/CHECK_EMAIL_EXISTS_REQUEST';
export const CHECK_EMAIL_EXISTS_SUCCESS =
  'app/ShoppingCartCheckoutPage/CHECK_EMAIL_EXISTS_SUCCESS';
export const CHECK_EMAIL_EXISTS_ERROR =
  'app/ShoppingCartCheckoutPage/CHECK_EMAIL_EXISTS_ERROR';

export const CREATE_FLEX_USER_REQUEST =
  'app/ShoppingCartCheckoutPage/CREATE_FLEX_USER_REQUEST';
export const CREATE_FLEX_USER_SUCCESS =
  'app/ShoppingCartCheckoutPage/CREATE_FLEX_USER_SUCCESS';
export const CREATE_FLEX_USER_ERROR =
  'app/ShoppingCartCheckoutPage/CREATE_FLEX_USER_ERROR';

export const VALIDATE_ADDRESS_REQUEST =
  'app/ShoppingCartCheckoutPage/VALIDATE_ADDRESS_REQUEST';
export const VALIDATE_ADDRESS_SUCCESS =
  'app/ShoppingCartCheckoutPage/VALIDATE_ADDRESS_SUCCESS';
export const VALIDATE_ADDRESS_ERROR =
  'app/ShoppingCartCheckoutPage/VALIDATE_ADDRESS_ERROR';

export const UPDATE_CHILD_CART_ORDER_REQUEST =
  'app/ShoppingCartCheckoutPage/UPDATE_CHILD_CART_ORDER_REQUEST';
export const UPDATE_CHILD_CART_ORDER_SUCCESS =
  'app/ShoppingCartCheckoutPage/UPDATE_CHILD_CART_ORDER_SUCCESS';
export const UPDATE_CHILD_CART_ORDER_ERROR =
  'app/ShoppingCartCheckoutPage/UPDATE_CHILD_CART_ORDER_ERROR';

export const UPDATE_ADDRESS_DETAILS_VALIDATION =
  'app/ShoppingCartCheckoutPage/UPDATE_ADDRESS_DETAILS_VALIDATION';

export const UPDATE_SHIPPING_SERVICE_VALIDATION =
  'app/ShoppingCartCheckoutPage/UPDATE_SHIPPING_SERVICE_VALIDATION';

export const CLEAR_VALIDATE_ADDRESS_RESULT =
  'app/ShoppingCartCheckoutPage/CLEAR_VALIDATE_ADDRESS_RESULT';

// ================ Reducer ================ //

const initialState = {
  storeListings: [],
  shippingProfiles: [],
  listings: [],
  orderData: null,
  transaction: null,
  initiateOrderError: null,
  requestOrderPaymentError: null,
  confirmPaymentError: null,
  stripeCustomerFetched: false,
  discountCodeData: null,
  checkDiscountCodeInProgress: false,
  checkDiscountCodeError: null,
  lineItems: [],
  fetchLineItemsInProgress: false,
  fetchLineItemsError: null,
  guestEmail: null,
  isGuestEmailExists: false,
  checkEmailExistsInProgress: false,
  checkEmailExistsError: null,
  createFlexUserInProgress: false,
  createFlexUserError: null,
  validateAddressResult: null,
  validateAddressInProgress: false,
  validateAddressError: null,
  oldAddressQuery: '',
  updateChildCartOrderError: null,

  // Address details validation
  isAddressValid: false,
  addressDetails: null,

  // Shipping service validation
  isShippingServiceValid: false,
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case INITIATE_ORDER_REQUEST:
      return { ...state, initiateOrderError: null };
    case INITIATE_ORDER_SUCCESS:
      return { ...state, transaction: payload };
    case INITIATE_ORDER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, initiateOrderError: payload };

    case REQUEST_ORDER_PAYMENT_REQUEST:
      return { ...state, requestOrderPaymentError: null };
    case REQUEST_ORDER_PAYMENT_SUCCESS:
      return state;
    case REQUEST_ORDER_PAYMENT_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, requestOrderPaymentError: payload };

    case CONFIRM_PAYMENT_REQUEST:
      return { ...state, confirmPaymentError: null };
    case CONFIRM_PAYMENT_SUCCESS:
      return state;
    case CONFIRM_PAYMENT_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, confirmPaymentError: payload };

    case STRIPE_CUSTOMER_REQUEST:
      return { ...state, stripeCustomerFetched: false };
    case STRIPE_CUSTOMER_SUCCESS:
      return { ...state, stripeCustomerFetched: true };
    case STRIPE_CUSTOMER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, stripeCustomerFetchError: payload };

    case CHECK_DISCOUNT_CODE_REQUEST:
      return {
        ...state,
        checkDiscountCodeInProgress: true,
        checkDiscountCodeError: null,
        discountCodeData: null,
      };
    case CHECK_DISCOUNT_CODE_SUCCESS:
      return {
        ...state,
        checkDiscountCodeInProgress: false,
        discountCodeData: payload,
      };
    case CHECK_DISCOUNT_CODE_ERROR:
      return {
        ...state,
        checkDiscountCodeInProgress: false,
        checkDiscountCodeError: payload,
      };

    case REMOVE_DISCOUNT_CODE_DATA:
      return {
        ...state,
        discountCodeData: null,
      };

    case FETCH_LINE_ITEMS_REQUEST:
      return {
        ...state,
        lineItems: [],
        fetchLineItemsInProgress: true,
        fetchLineItemsError: null,
      };
    case FETCH_LINE_ITEMS_SUCCESS:
      return { ...state, fetchLineItemsInProgress: false, lineItems: payload };
    case FETCH_LINE_ITEMS_ERROR:
      return {
        ...state,
        fetchLineItemsInProgress: false,
        fetchLineItemsError: payload,
      };

    case CHECK_EMAIL_EXISTS_REQUEST:
      return {
        ...state,
        isGuestEmailExists: false,
        checkEmailExistsInProgress: true,
        checkEmailExistsError: null,
      };
    case CHECK_EMAIL_EXISTS_SUCCESS:
      return {
        ...state,
        guestEmail: payload.email,
        isGuestEmailExists: payload.isEmailExists,
        checkEmailExistsInProgress: false,
      };
    case CHECK_EMAIL_EXISTS_ERROR:
      return {
        ...state,
        checkEmailExistsInProgress: false,
        checkEmailExistsError: payload,
      };

    case CREATE_FLEX_USER_REQUEST:
      return {
        ...state,
        createFlexUserInProgress: true,
        createFlexUserError: null,
      };
    case CREATE_FLEX_USER_SUCCESS:
      return {
        ...state,
        createFlexUserInProgress: false,
      };
    case CREATE_FLEX_USER_ERROR:
      return {
        ...state,
        createFlexUserInProgress: false,
        createFlexUserError: payload,
      };

    case CLEAR_VALIDATE_ADDRESS_RESULT:
      return {
        ...state,
        validateAddressResult: null,
        isAddressValid: false,
        addressDetails: null,
        oldAddressQuery: '',
      };
    case VALIDATE_ADDRESS_REQUEST:
      return {
        ...state,
        validateAddressInProgress: true,
        validateAddressError: null,
        validateAddressResult: null,
        addressDetails: null,
        isAddressValid: false,
      };
    case VALIDATE_ADDRESS_SUCCESS:
      return {
        ...state,
        validateAddressInProgress: false,
        validateAddressResult: payload,
        isAddressValid: payload.isValid,
        addressDetails: payload.address,
        oldAddressQuery: payload.oldAddressQuery,
      };
    case VALIDATE_ADDRESS_ERROR:
      return {
        ...state,
        validateAddressInProgress: false,
        validateAddressError: payload,
      };

    case UPDATE_CHILD_CART_ORDER_REQUEST:
      return { ...state, updateChildCartOrderError: null };
    case UPDATE_CHILD_CART_ORDER_SUCCESS:
      return state;
    case UPDATE_CHILD_CART_ORDER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, updateChildCartOrderError: payload };

    case UPDATE_SHIPPING_SERVICE_VALIDATION:
      return { ...state, isShippingServiceValid: payload };

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

const initiateOrderRequest = () => ({ type: INITIATE_ORDER_REQUEST });

const initiateOrderSuccess = order => ({
  type: INITIATE_ORDER_SUCCESS,
  payload: order,
});

const initiateOrderError = e => ({
  type: INITIATE_ORDER_ERROR,
  error: true,
  payload: e,
});

const requestOrderPaymentRequest = () => ({
  type: REQUEST_ORDER_PAYMENT_REQUEST,
});

const requestOrderPaymentSuccess = orderId => ({
  type: REQUEST_ORDER_PAYMENT_SUCCESS,
  payload: orderId,
});

const requestOrderPaymentError = e => ({
  type: REQUEST_ORDER_PAYMENT_ERROR,
  error: true,
  payload: e,
});

const confirmPaymentRequest = () => ({ type: CONFIRM_PAYMENT_REQUEST });

const confirmPaymentSuccess = orderId => ({
  type: CONFIRM_PAYMENT_SUCCESS,
  payload: orderId,
});

const confirmPaymentError = e => ({
  type: CONFIRM_PAYMENT_ERROR,
  error: true,
  payload: e,
});

export const checkDiscountCodeRequest = () => ({
  type: CHECK_DISCOUNT_CODE_REQUEST,
});
export const checkDiscountCodeSuccess = data => ({
  type: CHECK_DISCOUNT_CODE_SUCCESS,
  payload: data,
});
export const checkDiscountCodeError = e => ({
  type: CHECK_DISCOUNT_CODE_ERROR,
  error: true,
  payload: e,
});
export const removeDiscountCodeData = () => ({
  type: REMOVE_DISCOUNT_CODE_DATA,
});

export const fetchLineItemsRequest = () => ({ type: FETCH_LINE_ITEMS_REQUEST });
export const fetchLineItemsSuccess = lineItems => ({
  type: FETCH_LINE_ITEMS_SUCCESS,
  payload: lineItems,
});
export const fetchLineItemsError = error => ({
  type: FETCH_LINE_ITEMS_ERROR,
  error: true,
  payload: error,
});

export const stripeCustomerRequest = () => ({ type: STRIPE_CUSTOMER_REQUEST });
export const stripeCustomerSuccess = () => ({ type: STRIPE_CUSTOMER_SUCCESS });
export const stripeCustomerError = e => ({
  type: STRIPE_CUSTOMER_ERROR,
  error: true,
  payload: e,
});

export const checkEmailExistsRequest = () => ({
  type: CHECK_EMAIL_EXISTS_REQUEST,
});
export const checkEmailExistsSuccess = payload => ({
  type: CHECK_EMAIL_EXISTS_SUCCESS,
  payload,
});
export const checkEmailExistsError = e => ({
  type: CHECK_EMAIL_EXISTS_ERROR,
  error: true,
  payload: e,
});

export const createFlexUserRequest = () => ({
  type: CREATE_FLEX_USER_REQUEST,
});
export const createFlexUserSuccess = () => ({
  type: CREATE_FLEX_USER_SUCCESS,
});
export const createFlexUserError = e => ({
  type: CREATE_FLEX_USER_ERROR,
  error: true,
  payload: e,
});

export const validateAddressRequest = () => ({
  type: VALIDATE_ADDRESS_REQUEST,
});
export const validateAddressSuccess = data => ({
  type: VALIDATE_ADDRESS_SUCCESS,
  payload: data,
});
export const validateAddressError = e => ({
  type: VALIDATE_ADDRESS_ERROR,
  error: true,
  payload: e,
});

export const updateChildCartOrderRequest = () => ({
  type: UPDATE_CHILD_CART_ORDER_REQUEST,
});

export const updateChildCartOrderSuccess = () => ({
  type: UPDATE_CHILD_CART_ORDER_SUCCESS,
});

export const updateChildCartOrderError = e => ({
  type: UPDATE_CHILD_CART_ORDER_ERROR,
  error: true,
  payload: e,
});

export const updateShippingServiceValidation = data => ({
  type: UPDATE_SHIPPING_SERVICE_VALIDATION,
  payload: data,
});

export const clearValidationAddressResult = () => ({
  type: CLEAR_VALIDATE_ADDRESS_RESULT,
});

/* ================ Thunks ================ */

export const initiateOrder = (orderParams, processAlias, transitionName) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(initiateOrderRequest());

  const { orderData, storeListingIds, ...otherOrderParams } = orderParams;

  // Parameters for Marketplace API
  const transitionParams = {
    listingId: ADMIN_LISTING_ID,
    ...otherOrderParams,
  };

  const bodyParams = {
    processAlias,
    transition: transitionName,
    params: transitionParams,
  };
  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  const handleSuccess = response => {
    const entities = denormalisedResponseEntities(response);
    const order = entities[0];
    dispatch(initiateOrderSuccess(order));
    dispatch(fetchCurrentUserHasOrdersSuccess(true));
    return order;
  };

  const handleError = e => {
    dispatch(initiateOrderError(storableError(e)));
    log.error(e, 'initiate-order-failed', {
      ...orderData,
    });
    throw e;
  };

  return initiateTransaction({
    storeListingIds,
    orderData,
    bodyParams,
    queryParams,
  })
    .then(handleSuccess)
    .catch(handleError);
};

export const requestOrderPayment = (
  transactionId,
  transitionName,
  transitionParams = {},
  optionalPaymentMethods = {},
  orderData = {}
) => (dispatch, getState, sdk) => {
  dispatch(requestOrderPaymentRequest());

  const bodyParams = {
    id: transactionId,
    transition: transitionName,
    params: transitionParams,
    orderData,
  };

  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  return requestTransactionPayment({
    ...optionalPaymentMethods,
    bodyParams,
    queryParams,
  })
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      const order = entities[0];
      dispatch(requestOrderPaymentSuccess(order.id));
      return order;
    })
    .catch(e => {
      dispatch(requestOrderPaymentError(storableError(e)));
      const transactionIdMaybe = transactionId
        ? { transactionId: transactionId.uuid }
        : {};
      log.error(e, 'initiate-order-failed', {
        ...transactionIdMaybe,
      });
      throw e;
    });
};

export const sendMessage = params => (dispatch, getState, sdk) => {
  const message = params.message;
  const orderId = params.id;

  if (message) {
    return sdk.messages
      .send({ transactionId: orderId, content: message })
      .then(() => {
        return { orderId, messageSuccess: true };
      })
      .catch(e => {
        log.error(e, 'initial-message-send-failed', { txId: orderId });
        return { orderId, messageSuccess: false };
      });
  } else {
    return Promise.resolve({ orderId, messageSuccess: true });
  }
};

export const confirmPayment = (
  transactionId,
  transitionName,
  transitionParams = {}
) => (dispatch, getState, sdk) => {
  dispatch(confirmPaymentRequest());

  const bodyParams = {
    id: transactionId,
    transition: transitionName,
    params: transitionParams,
  };

  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  return confirmTransactionPayment({ bodyParams, queryParams })
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      const order = entities[0];
      dispatch(confirmPaymentSuccess(order.id));
      return order;
    })
    .catch(e => {
      dispatch(confirmPaymentError(storableError(e)));
      const transactionIdMaybe = transactionId
        ? { transactionId: transactionId.uuid }
        : {};
      log.error(e, 'initiate-order-failed', {
        ...transactionIdMaybe,
      });
      throw e;
    });
};

export const updateChildTransaction = (
  transactionId,
  transitionName,
  cartChildProcessAlias
) => (dispatch, getState, sdk) => {
  dispatch(updateChildCartOrderRequest());

  return initiateChildTransaction({
    transactionId,
    cartChildProcessAlias,
    initiateTransitionName: cartChildTransitions.CREATE_STORE_ORDER,
  })
    .then(response => {
      const childTransactionIds = response.data;

      const bodyParams = {
        id: transactionId,
        transition: transitionName,
        params: {
          protectedData: {
            childTransactionIds,
          },
        },
      };

      const queryParams = {
        include: ['booking', 'provider'],
        expand: true,
      };

      return sdk.transactions.transition(bodyParams, queryParams);
    })
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      const order = entities[0];
      dispatch(updateChildCartOrderSuccess());
      return { orderId: order.id };
    })
    .catch(e => {
      dispatch(updateChildCartOrderError(e));
      throw e;
    });
};

// StripeCustomer is a relantionship to currentUser
// We need to fetch currentUser with correct params to include relationship
export const stripeCustomer = () => (dispatch, getState, sdk) => {
  dispatch(stripeCustomerRequest());

  return dispatch(
    fetchCurrentUser({ include: ['stripeCustomer.defaultPaymentMethod'] })
  )
    .then(response => {
      dispatch(stripeCustomerSuccess());
    })
    .catch(e => {
      dispatch(stripeCustomerError(storableError(e)));
    });
};

export const checkDiscountCode = ({ code, storeIds, totalAmounts, email }) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(checkDiscountCodeRequest());

  return validateCoupon({ code, storeIds, totalAmounts, email })
    .then(response => {
      dispatch(checkDiscountCodeSuccess(response.data));
      return response.data;
    })
    .catch(e => {
      log.error(e, 'check-discount-code-failed', {
        code,
      });
      return dispatch(checkDiscountCodeError(storableError(e)));
    });
};

export const fetchTransactionLineItems = ({
  orderData,
  storeListingIds,
  transactionId,
}) => (dispatch, getState, sdk) => {
  dispatch(fetchLineItemsRequest());

  return transactionLineItems({
    orderData,
    storeListingIds,
    transactionId,
  })
    .then(response => {
      const lineItems = response.data;
      dispatch(fetchLineItemsSuccess(lineItems));
    })
    .catch(e => {
      dispatch(fetchLineItemsError(storableError(e)));
      log.error(e, 'fetching-line-items-failed', {
        storeListingIds,
        orderData,
      });
    });
};

export const checkUserEmailExists = email => (dispatch, getState, sdk) => {
  dispatch(checkEmailExistsRequest());

  return checkEmailExists(email)
    .then(response => {
      const { data: isEmailExists } = response;
      dispatch(checkEmailExistsSuccess({ email, isEmailExists }));
    })
    .catch(e => dispatch(checkEmailExistsError(storableError(e))));
};

export const createFlexUser = params => (dispatch, getState, sdk) => {
  dispatch(createFlexUserRequest());

  const { email, firstName, lastName } = params;

  const generatedPassword = Math.random()
    .toString(36)
    .slice(-8);

  const createUserParams = {
    email,
    password: generatedPassword,
    firstName,
    lastName,
    publicData: { email, isAutoCreateAccount: true, role: USER_TYPE.BUYER },
    privateData: { defaultPassword: generatedPassword },
  };

  return sdk.currentUser
    .create(createUserParams)
    .then(() => dispatch(login(email, generatedPassword)))
    .then(() => verifyEmail())
    .then(() => {
      dispatch(createFlexUserSuccess());
    })
    .catch(e => dispatch(createFlexUserError(storableError(e))));
};

export const validateRecipientAddressByGoogle = address => (
  dispatch,
  getState,
  sdk
) => {
  const { line1, line2, city, postalCode, state, country } = address;

  const unformattedAddress = `${line1}${
    line2 ? ` ${line2}` : ''
  }, ${city}, ${getLabelByKey(stateOptions, state)} ${postalCode}, ${country}`;
  const oldAddressQuery = getState().ShoppingCartCheckoutPage.oldAddressQuery;

  if (oldAddressQuery === unformattedAddress) {
    return;
  }

  dispatch(validateAddressRequest());

  return validateAddress(unformattedAddress, country)
    .then(response => {
      const isAddressInvalid = response.result.address.addressComponents?.some(
        item =>
          item.confirmationLevel !== ADDRESS_COMFIRMED ||
          item.replaced ||
          item.spellCorrected
      );

      dispatch(
        validateAddressSuccess({
          addressDetails: address,
          isValid: !isAddressInvalid,
          details: response.result.address,
          oldAddressQuery: unformattedAddress,
        })
      );
      return response;
    })
    .catch(e => {
      log.error(e, 'validate-address-failed', {
        address,
      });
      return dispatch(validateAddressError(storableError(e)));
    });
};

export const validateRecipientAddressByShippo = ({
  address,
  shippingProfiles,
}) => async (dispatch, getState, sdk) => {
  try {
    dispatch(validateAddressRequest());

    const validationResponse = await validateAddressByShippo({ address });
    const validationResult = validationResponse.data;
    const isValid =
      validationResult.analysis.validation_result.value === 'valid';
    const details = validationResult;

    dispatch(
      validateAddressSuccess({
        addressDetails: address,
        isValid,
        details,
      })
    );
  } catch (error) {
    log.error(error, 'validate-address-by-shippo-failed');
    dispatch(validateAddressError(storableError(error)));
  }
};

export const retrieveStripePaymentIntent = params => dispatch => {};
